const header = document.querySelector('header');
const headerHeight = 64;
let headerTopY = 0;
let headerBottomY = headerTopY + headerHeight;
let scrollY = 0;

document.addEventListener('scroll', () => {
  const newScrollY = window.scrollY > 0 ? window.scrollY : 0;
  const isFixed = header.style.position == "fixed";

  if (isFixed && newScrollY > scrollY) { // scrolling down
    setAbsolute(scrollY);
  }
  else if (newScrollY < scrollY) { // scrolling up
    handleUpScroll(newScrollY, isFixed);
  }

  scrollY = newScrollY;
}, { passive: true });

function setAbsolute(top) {
  header.style.position = "absolute";
  header.style.top = `${top}px`;
}

function handleUpScroll(newScrollY, isFixed) {
  headerBottomY = headerTopY + headerHeight;

  if (newScrollY > headerBottomY) { // below header bottom
    setHeaderTop(newScrollY);
  }
  else if (newScrollY <= headerTopY) { // at or above header top
    setFixed(newScrollY, isFixed);
  }
}

function setHeaderTop(top) {
  const newHeaderTopY = top - headerHeight;

  header.style.top = `${newHeaderTopY}px`;
  headerTopY = newHeaderTopY;
}

function setFixed(headerTop, isFixed) {
  if (!isFixed) {
    header.style.position = "fixed";
    header.style.top = "0";
  }

  headerTopY = headerTop;
  headerBottomY = headerTopY - headerHeight;
}